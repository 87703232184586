export const isToday = (someDate) => {
  const today = new Date();
  const date = new Date(someDate);
  return date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();
};

export const formatDateTime = (date) => {
  return new Date(date).toLocaleString('en-GB', { 
    dateStyle: 'short', 
    timeStyle: 'short' 
  });
};
