import React from 'react';
import * as dateUtils from '../../utils/dateUtils';
import { formatDateTime } from '../../utils/dateUtils';
import {
  calculateTotalWeight,
  calculateMissingReps,
  getTotalRowStyle,
  renderWeightliftingHeaders,
  renderCardioHeaders,
  renderProteinHeaders
} from '../../utils/tableUtils';

const RecordTable = ({ category, records, proteinRecords }) => {
  const renderTableHeaders = () => {
    switch (category) {
      case 'weightlifting':
        return renderWeightliftingHeaders();
      case 'cardio':
        return renderCardioHeaders();
      default:
        return renderProteinHeaders();
    }
  };

  const renderProteinRows = () => (
    proteinRecords.map((record, recordIndex) => (
      <tr key={recordIndex}>
        <td>{dateUtils.formatDateTime(record.created_at)}</td>
        <td>{record.grams}g</td>
        <td>{record.comment}</td>
      </tr>
    ))
  );

  const renderCardioRows = () => (
    records.map((record, recordIndex) => (
      <tr key={recordIndex} className={dateUtils.isToday(new Date(record.created_at)) ? '' : 'past-record'}>
        <td>{formatDateTime(record.created_at)}</td>
        <td>{record.duration}</td>
        <td>{record.watts}</td>
        <td>{record.heart_rate}</td>
        <td>{record.comment}</td>
      </tr>
    ))
  );

  const renderWeightliftingSet = (record, set, setIndex, isPast = false) => (
    <tr key={`${isPast ? 'prev' : 'today'}-${record.id}-${setIndex}`} className={isPast ? 'past-record' : ''}>
      <td>{formatDateTime(record.created_at)}</td>
      <td>{set.reps}</td>
      <td>{set.weight}</td>
      <td>{record.comment}</td>
    </tr>
  );

  const renderTotalRow = (label, total, isToday = false, todayTotal = 0, previousTotal = 0, lastSet = null) => {
    const style = isToday 
      ? { ...getTotalRowStyle(todayTotal, previousTotal), backgroundColor: todayTotal > previousTotal ? 'green' : 'red', color: 'white' } 
      : { fontWeight: 'bold', backgroundColor: '#666666', color: 'white' };
    const className = !isToday ? 'past-record' : '';
    
    // Calculate missing reps message for today's total row, even if no exercises done today
    const todayRecords = records.filter(record => dateUtils.isToday(new Date(record.created_at)));
    // Get all sets from today's records - they're already sorted by timestamp from the server
    const todaySets = todayRecords.reduce((sets, record) => [...sets, ...(record.sets || [])], []);
    const mostRecentSet = todaySets.length > 0 ? todaySets[todaySets.length - 1] : null;
    
    const missingRepsMessage = isToday && previousTotal > 0 ? (
      todayTotal === 0 && lastSet?.weight 
        ? ` ${Math.ceil(previousTotal / lastSet.weight)} x ${lastSet.weight}kg`
        : calculateMissingReps(todayTotal, previousTotal, lastSet, todaySets)
    ) : '';

    return (
      <>
        <tr key={`${isToday ? 'today' : 'prev'}-total`} style={style} className={className}>
          <td colSpan="2">{label}</td>
          <td colSpan="2">{total} kg</td>
        </tr>
        {isToday && previousTotal > 0 && todayTotal < previousTotal && (
          <tr key={`${isToday ? 'today' : 'prev'}-reps-left`} style={{ ...style, backgroundColor: 'red', color: 'white' }} className={className}>
            <td colSpan="4">TO DO: {missingRepsMessage}</td>
          </tr>
        )}
      </>
    );
  };

  const renderWeightliftingRows = () => {
    const todayRecords = records.filter(record => dateUtils.isToday(new Date(record.created_at)));
    const previousRecords = records.filter(record => !dateUtils.isToday(new Date(record.created_at)));
    
    const todayTotal = calculateTotalWeight(todayRecords.flatMap(r => r.sets || []));
    const previousTotal = calculateTotalWeight(previousRecords.flatMap(r => r.sets || []));
    // Get the last set from previous day's records
    const lastSet = previousRecords[0]?.sets[previousRecords[0]?.sets.length - 1];

    return [
      renderTotalRow(
        'TOTAL WEIGHT LIFTED TODAY',
        todayTotal,
        true,
        todayTotal,
        previousTotal,
        lastSet
      ),
      ...todayRecords.flatMap(record => 
        (record.sets || []).map((set, index) => renderWeightliftingSet(record, set, index))
      ),
      previousRecords.length > 0 && (
        <React.Fragment key="prev-total">
          {renderTotalRow(
            'TOTAL WEIGHT LIFTED PREVIOUS DAY',
            previousTotal
          )}
        </React.Fragment>
      ),
      ...previousRecords.flatMap(record =>
        (record.sets || []).map((set, index) => renderWeightliftingSet(record, set, index, true))
      )
    ].filter(Boolean);
  };

  const renderTableRows = () => {
    switch (category) {
      case 'protein':
        return renderProteinRows();
      case 'weightlifting':
        return renderWeightliftingRows();
      case 'cardio':
        return renderCardioRows();
      default:
        return null;
    }
  };

  return (
    <div className="previous-records" style={{ marginTop: '-1px' }}>
      <table style={{ marginTop: '0' }}>
        <thead>
          {renderTableHeaders()}
        </thead>
        <tbody>
          {renderTableRows()}
        </tbody>
      </table>
    </div>
  );
};

export default RecordTable;
