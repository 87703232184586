import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSupabase } from '../contexts/SupabaseContext';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { enUS } from 'date-fns/locale';
import { FaHeart, FaDumbbell } from 'react-icons/fa';
import { iconBPM, iconWatts, iconStrength, iconDumbell, iconPercentage, iconTime, iconNumber } from './Images';

Chart.register(...registerables);

function Progress() {
  const [category, setCategory] = useState(() => localStorage.getItem('lastCategory') || 'weightlifting');
  const [user, setUser] = useState(() => localStorage.getItem('lastUser') || 'Ruben');
  const [exercise, setExercise] = useState(() => localStorage.getItem('lastActivity') || '');
  const [exercises, setExercises] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const supabase = useSupabase();
  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);
  const chartRef3 = useRef(null);
  const chartRef4 = useRef(null);
  const chartRef5 = useRef(null);
  const chartInstance1 = useRef(null);
  const chartInstance2 = useRef(null);
  const chartInstance3 = useRef(null);
  const chartInstance4 = useRef(null);
  const chartInstance5 = useRef(null);

  const fetchChartData = useCallback(async () => {
    setIsLoading(true);
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

    let query;
    if (category === 'protein') {
      query = supabase
        .from('protein')
        .select('*')
        .eq('username', user)
        .gte('created_at', sixMonthsAgo.toISOString())
        .order('created_at', { ascending: true });
    } else {
      query = supabase
        .from('exercises')
        .select(`
          *,
          exercise_list!inner(name, type)
        `)
        .eq('username', user)
        .eq('exercise_list.name', exercise)
        .eq('exercise_list.type', category)
        .gte('created_at', sixMonthsAgo.toISOString())
        .order('created_at', { ascending: true });
    }

    const { data, error } = await query;

    if (error) {
      console.error('Error fetching chart data:', error);
    } else {
      processChartData(data);
    }
    setIsLoading(false);
  }, [user, exercise, category, supabase]);

  useEffect(() => {
    const fetchAndSetExercise = async () => {
      if (category === 'protein') {
        setExercises([{ id: 'protein', name: 'Protein' }]);
        setExercise('Protein');
      } else {
        const { data, error } = await supabase
          .from('exercise_list')
          .select('*')
          .eq('type', category)
          .order('name', { ascending: true });

        if (error) {
          console.error('Error fetching exercises:', error);
        } else {
          setExercises(data);
          // Get the stored exercise for this category
          const storedExercise = localStorage.getItem('lastActivity');
          // Check if the stored exercise exists in the new exercise list
          const exerciseExists = data.some(ex => ex.name === storedExercise);
          if (exerciseExists) {
            setExercise(storedExercise);
          } else {
            // If stored exercise doesn't exist in new category, set to first exercise
            setExercise(data[0]?.name || '');
          }
        }
      }
    };

    fetchAndSetExercise();
  }, [category, supabase]);

  useEffect(() => {
    fetchChartData();
  }, [fetchChartData]);

  useEffect(() => {
    if (!isLoading && chartData && chartRef1.current) {
      renderCharts();
    }
  }, [chartData, category, isLoading]);

  async function fetchExercises() {
    if (category === 'protein') {
      setExercises([{ id: 'protein', name: 'Protein' }]);
      setExercise('Protein');
    } else {
      const { data, error } = await supabase
        .from('exercise_list')
        .select('*')
        .eq('type', category)
        .order('name', { ascending: true });

      if (error) {
        console.error('Error fetching exercises:', error);
      } else {
        setExercises(data);
        if (data.length > 0) {
          setExercise(data[0].name);
        }
      }
    }
  }

  // Remove the fetchChartData function from here as it's now defined in the useCallback above

  function processChartData(data) {
    if (category === 'protein') {
      const groupedData = data.reduce((acc, record) => {
        const date = record.created_at.split('T')[0];
        if (!acc[date]) {
          acc[date] = { totalGrams: 0 };
        }
        acc[date].totalGrams += record.grams || 0;
        return acc;
      }, {});

      const chartData = Object.entries(groupedData).map(([date, values]) => ({
        date,
        totalGrams: values.totalGrams
      }));

      setChartData(chartData);
    } else if (category === 'cardio') {
      const groupedData = data.reduce((acc, record) => {
        const date = record.created_at.split('T')[0];
        if (!acc[date]) {
          acc[date] = { totalTime: 0, totalWatts: 0, totalHeartRate: 0, count: 0 };
        }
        acc[date].totalTime += record.duration || 0;
        acc[date].totalWatts += record.watts || 0;
        acc[date].totalHeartRate += record.heart_rate || 0;
        acc[date].count += 1;
        return acc;
      }, {});

      const chartData = Object.entries(groupedData).map(([date, values]) => ({
        date,
        totalTime: values.totalTime,
        averageWatts: values.totalWatts / values.count,
        averageHeartRate: values.totalHeartRate / values.count,
      }));

      setChartData(chartData);
    } else {
      const groupedData = data.reduce((acc, record) => {
        const date = record.created_at.split('T')[0];
        if (!acc[date]) {
          acc[date] = { totalReps: 0, totalWeight: 0, totalWeightLifted: 0, count: 0 };
        }
        if (record.sets && Array.isArray(record.sets)) {
          record.sets.forEach(set => {
            const reps = set.reps || 0;
            const weight = set.weight || 0;
            acc[date].totalReps += reps;
            acc[date].totalWeight += weight;
            acc[date].totalWeightLifted += reps * weight;
            acc[date].count += 1;
          });
        }
        return acc;
      }, {});

      const chartData = Object.entries(groupedData).map(([date, values]) => ({
        date,
        totalReps: values.totalReps,
        averageWeight: values.count > 0 ? values.totalWeight / values.count : 0,
        totalWeightLifted: values.totalWeightLifted,
      }));

      setChartData(chartData);
    }
  }

  function renderCharts() {
    if (!chartRef1.current) {
      console.error('Chart canvas element is not available');
      return;
    }

    if (chartInstance1.current) {
      chartInstance1.current.destroy();
    }

    const ctx1 = chartRef1.current.getContext('2d');

    if (category === 'protein') {
      chartInstance1.current = new Chart(ctx1, {
        type: 'bar',
        data: {
          datasets: [
            {
              label: 'Total Protein (grams)',
              data: chartData.map(d => ({ x: d.date, y: d.totalGrams })),
              backgroundColor: 'rgb(153, 102, 255)',
              borderColor: 'rgb(153, 102, 255)',
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'day',
                tooltipFormat: 'yyyy-MM-dd (EEEE)',
              },
              title: {
                display: true,
                text: 'Date',
              },
            },
            y: {
              type: 'linear',
              display: true,
              title: {
                display: true,
                text: 'Total Protein (grams)',
              },
              beginAtZero: true,
            },
          },
        },
      });
    } else if (category === 'cardio') {
      // Chart 1: Average Watts
      chartInstance1.current = new Chart(ctx1, {
        type: 'line',
        data: {
          datasets: [
            {
              label: 'Average Watts',
              data: chartData.map(d => ({ x: d.date, y: d.averageWatts })),
              borderColor: 'rgb(0, 0, 0)', // Black
              pointStyle: function(context) {
                const image = new Image(20, 20);
                image.src = iconWatts;
                return image;
              },
              pointRadius: 18,
              pointHoverRadius: 24,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              labels: {
                usePointStyle: true,
              },
            },
          },
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'day',
              },
              adapters: {
                date: {
                  locale: enUS,
                },
              },
              title: {
                display: true,
                text: 'Date',
              },
            },
            y: {
              type: 'linear',
              display: true,
              title: {
                display: true,
                text: 'Average Watts',
              },
            },
          },
        },
      });

      // Chart 2: Average BPM
      if (chartRef2.current) {
        const ctx2 = chartRef2.current.getContext('2d');
        if (chartInstance2.current) {
          chartInstance2.current.destroy();
        }
        chartInstance2.current = new Chart(ctx2, {
          type: 'line',
          data: {
            datasets: [
              {
                label: 'Average BPM',
                data: chartData.map(d => ({ x: d.date, y: d.averageHeartRate })),
                borderColor: 'rgb(255, 0, 0)', // Red
                pointStyle: function(context) {
                  const image = new Image(36, 36);
                  image.src = iconBPM;
                  return image;
                },
                pointRadius: 18,
                pointHoverRadius: 24,
              },
            ],
          },
          options: {
            responsive: true,
            plugins: {
              legend: {
                labels: {
                  usePointStyle: true,
                },
              },
            },
            scales: {
              x: {
                type: 'time',
                time: {
                  unit: 'day',
                },
                adapters: {
                  date: {
                    locale: enUS,
                  },
                },
                title: {
                  display: true,
                  text: 'Date',
                },
              },
              y: {
                type: 'linear',
                display: true,
                title: {
                  display: true,
                  text: 'Average BPM',
                },
                ticks: {
                  color: 'red'
                }
              },
            },
          },
        });
      }

      // Chart 3: Watts/BPM
      if (chartRef3.current) {
        const ctx3 = chartRef3.current.getContext('2d');
        if (chartInstance3.current) {
          chartInstance3.current.destroy();
        }
        chartInstance3.current = new Chart(ctx3, {
          type: 'line',
          data: {
            datasets: [
              {
                label: 'Watts/BPM (Higher is Better)',
                data: chartData.map(d => ({ x: d.date, y: d.averageWatts / d.averageHeartRate })),
                borderColor: 'rgb(75, 192, 192)', // Teal
                pointRadius: 18,
                pointHoverRadius: 24,
                pointStyle: function(context) {
                  const image = new Image(26, 26);
                  image.src = iconPercentage;
                  return image;
                },
              },
            ],
          },
          options: {
            responsive: true,
            scales: {
              x: {
                type: 'time',
                time: {
                  unit: 'day',
                  tooltipFormat: 'yyyy-MM-dd (EEEE)',
                },
                title: {
                  display: true,
                  text: 'Date',
                },
              },
              y: {
                type: 'linear',
                display: true,
                title: {
                  display: true,
                  text: 'Watts/BPM',
                },
              },
            },
          },
        });
      }

      // Chart 4: Total Time
      if (chartRef4.current) {
        const ctx4 = chartRef4.current.getContext('2d');
        if (chartInstance4.current) {
          chartInstance4.current.destroy();
        }
        chartInstance4.current = new Chart(ctx4, {
          type: 'line',
          data: {
            datasets: [
              {
                label: 'Total Time (minutes)',
                data: chartData.map(d => ({ x: d.date, y: d.totalTime })),
                borderColor: 'rgb(0, 0, 0)', // Black
                pointRadius: 18,
                pointHoverRadius: 24,
                pointStyle: function(context) {
                  const image = new Image(36, 36);
                  image.src = iconTime;
                  return image;
                },
              },
            ],
          },
          options: {
            responsive: true,
            scales: {
              x: {
                type: 'time',
                time: {
                  unit: 'day',
                  tooltipFormat: 'yyyy-MM-dd (EEEE)',
                },
                title: {
                  display: true,
                  text: 'Date',
                },
              },
              y: {
                type: 'linear',
                display: true,
                title: {
                  display: true,
                  text: 'Total Time (minutes)',
                },
              },
            },
          },
        });
      }
    } else {
      // Weightlifting chart
      // Chart 1: Total Weight Lifted
      chartInstance1.current = new Chart(ctx1, {
        type: 'line',
        data: {
          datasets: [
            {
              label: `${exercise} - Total Weight Lifted (kg)`,
              data: chartData.map(d => ({ x: d.date, y: d.totalWeightLifted })),
              borderColor: 'rgb(75, 192, 192)', // Teal
              pointRadius: 18,
              pointHoverRadius: 24,
              pointStyle: function(context) {
                if (context.parsed && context.parsed.y !== undefined) {
                  const size = Math.max(context.parsed.y / 40, 20); // Ensure a minimum size
                  const image = new Image(size, size);
                  image.src = iconStrength;
                  return image;
                }
                return 'circle'; // Fallback to default circle if data is not available
              },
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              labels: {
                usePointStyle: true,
                generateLabels: (chart) => {
                  const datasets = chart.data.datasets;
                  return datasets.map((dataset, i) => ({
                    text: dataset.label,
                    fillStyle: dataset.borderColor,
                    strokeStyle: dataset.borderColor,
                    lineWidth: 2,
                    hidden: !chart.isDatasetVisible(i),
                    index: i,
                    pointStyle: dataset.pointStyle || 'circle',
                  }));
                },
              },
            },
          },
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'day',
                tooltipFormat: 'yyyy-MM-dd (EEEE)',
              },
              title: {
                display: true,
                text: 'Date',
              },
            },
            y: {
              type: 'linear',
              display: true,
              title: {
                display: true,
                text: 'Total Weight Lifted (kg)',
              },
            },
          },
        },
      });

      // Chart 2: Average Weight
      if (chartRef2.current) {
        const ctx2 = chartRef2.current.getContext('2d');
        if (chartInstance2.current) {
          chartInstance2.current.destroy();
        }
        chartInstance2.current = new Chart(ctx2, {
          type: 'line',
          data: {
            datasets: [
              {
                label: `${exercise} - Average Weight (kg)`,
                data: chartData.map(d => ({ x: d.date, y: d.averageWeight })),
                borderColor: 'grey',
                pointRadius: 18,
                pointHoverRadius: 24,
                pointStyle: function(context) {
                  if (context.parsed && context.parsed.y !== undefined) {
                    const size = Math.max(context.parsed.y - 20, 20);
                    const image = new Image(size, size);
                    image.src = iconDumbell;
                    return image;
                  }
                  return 'circle'; // Fallback to default circle if data is not available
                },
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                labels: {
                  usePointStyle: true,
                },
              },
            },
            scales: {
              x: {
                type: 'time',
                time: {
                  unit: 'day',
                  tooltipFormat: 'yyyy-MM-dd (EEEE)',
                },
                title: {
                  display: true,
                  text: 'Date',
                },
              },
              y: {
                type: 'linear',
                display: true,
                title: {
                  display: true,
                  text: 'Average Weight (kg)',
                },
                ticks: {
                  color: 'grey'
                }
              },
            },
          },
        });
      }

      // Chart 3: Total Reps
      if (chartRef3.current) {
        const ctx3 = chartRef3.current.getContext('2d');
        if (chartInstance3.current) {
          chartInstance3.current.destroy();
        }
        chartInstance3.current = new Chart(ctx3, {
          type: 'line',
          data: {
            datasets: [
              {
                label: `${exercise} - Total Number of Reps`,
                data: chartData.map(d => ({ x: d.date, y: d.totalReps })),
                borderColor: 'rgb(255, 99, 132)', // Pink
                pointRadius: 18,
                pointHoverRadius: 24,
                pointStyle: function(context) {
                  if (context.parsed && context.parsed.y !== undefined) {
                    const size = Math.max(context.parsed.y, 20); // Ensure a minimum size
                    const image = new Image(size, size);
                    image.src = iconNumber;
                    return image;
                  }
                  return 'circle';
                }
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                labels: {
                  usePointStyle: true,
                },
              },
            },
            scales: {
              x: {
                type: 'time',
                time: {
                  unit: 'day',
                  tooltipFormat: 'yyyy-MM-dd (EEEE)',
                },
                title: {
                  display: true,
                  text: 'Date',
                },
              },
              y: {
                type: 'linear',
                display: true,
                title: {
                  display: true,
                  text: 'Total Number of Reps',
                },
                ticks: {
                  color: 'red'
                }
              },
            },
          },
        });
      }
    }
  }

  return (
    <div>
      <h2>Progress</h2>
      <div>
        <label>
          Category:
          <select value={category} onChange={(e) => {
            const newCategory = e.target.value;
            setCategory(newCategory);
            localStorage.setItem('lastCategory', newCategory);
            setExercise('');
            localStorage.removeItem('lastActivity');
          }}>
            <option value="weightlifting">Weightlifting</option>
            <option value="cardio">Cardio</option>
            <option value="protein">Protein</option>
          </select>
        </label>
      </div>
      <div>
        <label>
          User:
          <select value={user} onChange={(e) => {
            const newUser = e.target.value;
            setUser(newUser);
            localStorage.setItem('lastUser', newUser);
          }}>
            <option value="Ruben">Ruben</option>
            <option value="Milena">Milena</option>
          </select>
        </label>
      </div>
      {category !== 'protein' && (
        <div>
          <label>
            Exercise:
            <select value={exercise} onChange={(e) => {
              const newExercise = e.target.value;
              setExercise(newExercise);
              localStorage.setItem('lastActivity', newExercise);
            }}>
              {exercises.map((ex) => (
                <option key={ex.id} value={ex.name}>{ex.name}</option>
              ))}
            </select>
          </label>
        </div>
      )}
      {isLoading ? (
        <div>Loading chart data...</div>
      ) : category === 'protein' ? (
        <div style={{ width: '100%', height: '400px', marginBottom: '20px' }}>
          <h3>Total Protein Intake (grams)</h3>
          <canvas ref={chartRef1}></canvas>
        </div>
      ) : category === 'cardio' ? (
        <>
          <div style={{ width: '100%', height: '400px', marginBottom: '20px' }}>
            <h3>Average Watts</h3>
            <canvas ref={chartRef1}></canvas>
          </div>
          <div style={{ width: '100%', height: '400px', marginBottom: '20px' }}>
            <h3>Average BPM</h3>
            <canvas ref={chartRef2}></canvas>
          </div>
          <div style={{ width: '100%', height: '400px', marginBottom: '20px' }}>
            <h3>Watts/BPM (Higher is Better)</h3>
            <canvas ref={chartRef3}></canvas>
          </div>
          <div style={{ width: '100%', height: '400px' }}>
            <h3>Total Time</h3>
            <canvas ref={chartRef4}></canvas>
          </div>
        </>
      ) : (
        <>
          <div style={{ width: '100%', height: '400px', marginBottom: '40px' }}>
            <h3>{exercise} - Total Weight Lifted</h3>
            <div style={{ position: 'relative', height: '100%', width: '100%' }}>
              <canvas ref={chartRef1}></canvas>
            </div>
          </div>
          <div style={{ width: '100%', height: '400px', marginBottom: '40px' }}>
            <h3>{exercise} - Average Weight</h3>
            <div style={{ position: 'relative', height: '100%', width: '100%' }}>
              <canvas ref={chartRef2}></canvas>
            </div>
          </div>
          <div style={{ width: '100%', height: '400px', marginBottom: '40px' }}>
            <h3>{exercise} - Total Reps</h3>
            <div style={{ position: 'relative', height: '100%', width: '100%' }}>
              <canvas ref={chartRef3}></canvas>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Progress;
