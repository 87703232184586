import React, { useState, useEffect, useCallback } from 'react';
import { useSupabase } from '../contexts/SupabaseContext';

function Diary() {
  const [exercises, setExercises] = useState([]);
  const [proteinEntries, setProteinEntries] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [exerciseDates, setExerciseDates] = useState([]);
  const supabase = useSupabase();

  const groupExercises = useCallback((data) => {
    const grouped = {};
    data.forEach(exercise => {
      const exerciseName = exercise.exercise_list.name;
      const exerciseType = exercise.exercise_list.type;
      const key = `${exerciseType}-${exerciseName}`;
      if (!grouped[key]) {
        grouped[key] = { ...exercise, name: exerciseName, type: exerciseType, users: {} };
      }
      if (exercise.exercise_list.type === 'weightlifting') {
        if (!grouped[key].users[exercise.username]) {
          grouped[key].users[exercise.username] = [];
        }
        grouped[key].users[exercise.username].push({
          id: exercise.id,
          sets: exercise.sets || [],
          comment: exercise.comment,
          created_at: exercise.created_at
        });
      } else {
        if (!grouped[key].users[exercise.username]) {
          grouped[key].users[exercise.username] = [];
        }
        grouped[key].users[exercise.username].push({
          id: exercise.id,
          duration: exercise.duration,
          watts: exercise.watts,
          heart_rate: exercise.heart_rate,
          comment: exercise.comment,
          created_at: exercise.created_at
        });
      }
    });

    // Sort sets by date in descending order
    Object.values(grouped).forEach(exercise => {
      if (exercise.type === 'weightlifting') {
        Object.values(exercise.users).forEach(userEntries => {
          userEntries.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          userEntries.forEach(entry => {
            if (entry.sets) {
              entry.sets.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            }
          });
        });
      }
    });

    return Object.values(grouped);
  }, []);

  const fetchProteinEntries = useCallback(async () => {
    const { data, error } = await supabase
      .from('protein')
      .select('*')
      .gte('created_at', `${selectedDate}T00:00:00`)
      .lt('created_at', `${selectedDate}T23:59:59`)
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error fetching protein entries:', error);
    } else {
      // Group protein entries by username
      const groupedEntries = data.reduce((acc, entry) => {
        if (!acc[entry.username]) {
          acc[entry.username] = [];
        }
        acc[entry.username].push(entry);
        return acc;
      }, {});
      setProteinEntries(groupedEntries);
    }
  }, [supabase, selectedDate]);

  const fetchExercises = useCallback(async () => {
    const { data, error } = await supabase
      .from('exercises')
      .select(`
        *,
        exercise_list!exercises_name_id_fkey (
          id,
          name,
          type
        )
      `)
      .gte('created_at', `${selectedDate}T00:00:00`)
      .lt('created_at', `${selectedDate}T23:59:59`)
      .order('exercise_list(name)', { ascending: true })
      .order('username', { ascending: true })
      .order('created_at', { ascending: true });

    if (error) {
      console.error('Error fetching exercises:', error);
    } else {
      const groupedExercises = groupExercises(data);
      setExercises(groupedExercises);
    }
  }, [supabase, selectedDate, groupExercises]);

  const fetchExerciseDates = useCallback(async () => {
    const { data, error } = await supabase
      .from('exercises')
      .select('created_at')
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error fetching exercise dates:', error);
    } else {
      const uniqueDates = [...new Set(data.map(item => item.created_at.split('T')[0]))];
      setExerciseDates(uniqueDates);
    }
  }, [supabase]);

  useEffect(() => {
    fetchExerciseDates();
    fetchExercises();
    fetchProteinEntries();
  }, [fetchExerciseDates, fetchExercises, fetchProteinEntries]);

  function changeDate(direction) {
    const currentIndex = exerciseDates.indexOf(selectedDate);

    if (direction === 'next') {
      if (currentIndex > 0) {
        setSelectedDate(exerciseDates[currentIndex - 1]);
      } else {
        // If there are no next dates, go to today
        setSelectedDate(new Date().toISOString().split('T')[0]);
      }
    } else {
      if (currentIndex < exerciseDates.length - 1) {
        setSelectedDate(exerciseDates[currentIndex + 1]);
      }
      // If there are no previous dates, stay on the current date
    }
  }

  async function deleteExercise(exerciseId, exerciseName, isProtein = false) {
    const isConfirmed = window.confirm(`Are you sure you want to delete this ${exerciseName} ${isProtein ? 'entry' : 'exercise'}?`);
    if (isConfirmed) {
      const { error } = await supabase
        .from(isProtein ? 'protein' : 'exercises')
        .delete()
        .eq('id', exerciseId);

      if (error) {
        console.error(`Error deleting ${isProtein ? 'protein entry' : 'exercise'}:`, error);
      } else {
        if (isProtein) {
          fetchProteinEntries();
        } else {
          fetchExercises();
        }
      }
    }
  }

  return (
    <div>
      <h2>Diary</h2>
      <div>
        <button onClick={() => changeDate('prev')} className="btn">&lt;</button>
        <span>{new Date(selectedDate).toLocaleDateString()}</span>
        <button onClick={() => changeDate('next')} className="btn">&gt;</button>
      </div>
      <div className="diary">
        <h3>🥩 Protein</h3>
        {Object.entries(proteinEntries).map(([username, entries]) => (
          <div key={username} className="exercise-item">
            <h4>{username}</h4>
            {(() => {
              const totalProtein = entries.reduce((sum, entry) => sum + (entry.grams || 0), 0);
              const proteinLeft = 110 - totalProtein;
              const hasReachedTarget = totalProtein >= 110;
              return (
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                  <tbody>
                    <tr style={{ 
                      backgroundColor: hasReachedTarget ? '#d4edda' : '#f8d7da',
                      color: hasReachedTarget ? '#155724' : '#721c24',
                      fontWeight: 'bold'
                    }}>
                      <td className="diary-table-row-centered">
                        {hasReachedTarget ? 'Protein target reached!' : `Protein left today: ${proteinLeft}g`}
                      </td>
                    </tr>
                  </tbody>
                </table>
              );
            })()}
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th className="diary-table-row-centered" style={{ width: '12%' }}>Time</th>
                  <th className="diary-table-row-centered" style={{ width: '20%' }}>Grams</th>
                  <th className="diary-table-row" style={{ width: '56%' }}>Comment</th>
                  <th className="diary-table-row-centered" style={{ width: '10%' }}></th>
                </tr>
              </thead>
              <tbody>
                {entries.map((entry) => (
                  <tr key={entry.id}>
                    <td className="diary-table-row-centered" style={{ width: '12%' }}>
                      {new Date(entry.created_at).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit' })}
                    </td>
                    <td className="diary-table-row-centered" style={{ width: '20%' }}>{entry.grams}g</td>
                    <td className="diary-table-row" style={{ width: '56%' }}>{entry.comment}</td>
                    <td className="diary-table-row-centered" style={{ width: '10%' }}>
                      <button onClick={() => deleteExercise(entry.id, 'protein', true)} className="btn-secondary btn-sm">X</button>
                    </td>
                  </tr>
                ))}
                <tr style={{ fontWeight: 'bold', backgroundColor: '#f8f9fa' }}>
                  <td className="diary-table-row-centered" style={{ width: '12%' }}>Total</td>
                  <td className="diary-table-row-centered" style={{ width: '20%' }}>
                    {entries.reduce((sum, entry) => sum + (entry.grams || 0), 0)}g
                  </td>
                  <td className="diary-table-row-centered" style={{ width: '56%' }}></td>
                  <td className="diary-table-row-centered" style={{ width: '10%' }}></td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}

        <h3>🏃 Cardio</h3>
        {exercises.filter(exercise => exercise.type === 'cardio').map((exercise) => (
          <div key={exercise.id} className="exercise-item">
            <h4>{exercise.name}</h4>
            {Object.entries(exercise.users).map(([username, data]) => (
              <div key={username}>
                <h5>{username}</h5>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr>
                      <th className="diary-table-row-centered" style={{ width: '12%' }}>Time</th>
                      <th className="diary-table-row-centered" style={{ width: '10%' }}>Duration (min)</th>
                      <th className="diary-table-row-centered" style={{ width: '10%' }}>Watts</th>
                      <th className="diary-table-row-centered" style={{ width: '10%' }}>BPM</th>
                      <th className="diary-table-row" style={{ width: '36%' }}>Comment</th>
                      <th className="diary-table-row-centered" style={{ width: '10%' }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((entry) => (
                      <tr key={entry.id}>
                        <td className="diary-table-row-centered" style={{ width: '12%' }}>
                          {new Date(entry.created_at).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit' })}
                        </td>
                        <td className="diary-table-row-centered" style={{ width: '10%' }}>{entry.duration}</td>
                        <td className="diary-table-row-centered" style={{ width: '10%' }}>{entry.watts}</td>
                        <td className="diary-table-row-centered" style={{ width: '10%' }}>{entry.heart_rate}</td>
                        <td className="diary-table-row" style={{ width: '36%' }}>{entry.comment}</td>
                        <td className="diary-table-row-centered" style={{ width: '10%' }}>
                          <button onClick={() => deleteExercise(entry.id, exercise.name)} className="btn-secondary btn-sm">X</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        ))}
        
        <h3>🏋️ Weightlifting</h3>
        {exercises.filter(exercise => exercise.type === 'weightlifting').map((exercise) => (
          <div key={exercise.id} className="exercise-item">
            <h4>{exercise.name}</h4>
            {Object.entries(exercise.users).map(([username, data]) => (
              <div key={username}>
                <h4>{username}</h4>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr>
                      <th className="diary-table-row-centered" style={{ width: '12%' }}>Time</th>
                      <th className="diary-table-row-centered" style={{ width: '10%' }}>Reps</th>
                      <th className="diary-table-row-centered" style={{ width: '10%' }}>Weight (kg)</th>
                      <th className="diary-table-row" style={{ width: '36%' }}>Comment</th>
                      <th className="diary-table-row-centered" style={{ width: '10%' }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((entry, index) => (
                      <React.Fragment key={entry.id}>
                        {exercise.type === 'weightlifting' ? (
                          entry.sets && entry.sets.map((set, setIndex) => (
                            <tr key={`${entry.id}-${setIndex}`}>
                              <td className="diary-table-row-centered" style={{ width: '12%' }}>
                                {new Date(entry.created_at).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit' })}
                              </td>
                              <td className="diary-table-row-centered" style={{ width: '10%' }}>{set.reps}</td>
                              <td className="diary-table-row-centered" style={{ width: '10%' }}>{set.weight}</td>
                              <td className="diary-table-row" style={{ width: '36%' }}>{setIndex === 0 ? entry.comment : ''}</td>
                              <td className="diary-table-row-centered" style={{ width: '10%' }}>
                                {setIndex === 0 && <button onClick={() => deleteExercise(entry.id, exercise.name)} className="btn-secondary btn-sm">X</button>}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td className="diary-table-row-centered" style={{ width: '12%' }}>
                              {new Date(entry.created_at).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit' })}
                            </td>
                            <td className="diary-table-row-centered" style={{ width: '10%' }}>{entry.duration}</td>
                            <td className="diary-table-row-centered" style={{ width: '10%' }}>{entry.watts}</td>
                            <td className="diary-table-row-centered" style={{ width: '10%' }}>{entry.heart_rate}</td>
                            <td className="diary-table-row" style={{ width: '36%' }}>{entry.comment}</td>
                            <td className="diary-table-row-centered" style={{ width: '10%' }}>
                              <button onClick={() => deleteExercise(entry.id, exercise.name)} className="btn-secondary btn-sm">X</button>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Diary;
