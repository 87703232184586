import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { enUS } from 'date-fns/locale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iconStrength } from './Images';
import { faDumbbell, faRunning, faDrumstickBite } from '@fortawesome/free-solid-svg-icons';
import { useSupabase } from '../contexts/SupabaseContext';
import LoadingOverlay from './LoadingOverlay';
import WeightliftingForm from './forms/WeightliftingForm';
import CardioForm from './forms/CardioForm';
import RecordTable from './tables/RecordTable';
import { isToday, formatDateTime } from '../utils/dateUtils';

function Record() {
  const formRef = useRef(null);
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [chartData, setChartData] = useState(null);
  const [category, setCategory] = useState(() => localStorage.getItem('lastCategory') || 'weightlifting');
  const [exerciseList, setExerciseList] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState(() => {
    const lastCategory = localStorage.getItem('lastCategory');
    const lastActivity = localStorage.getItem(`lastActivity_${lastCategory}`);
    return lastCategory === 'protein' ? '' : (lastActivity || '');
  });

  useEffect(() => {
    const lastActivity = localStorage.getItem(`lastActivity_${category}`);
    if (lastActivity) {
      setSelectedActivity(lastActivity);
    } else {
      setSelectedActivity('');
    }
  }, [category]);
  const [username, setUsername] = useState(() => localStorage.getItem('lastUser') || 'Ruben');
  const [reps, setReps] = useState('');
  const [weight, setWeight] = useState('');
  const [duration, setDuration] = useState('');
  const [watts, setWatts] = useState('');
  const [protein, setProtein] = useState('');
  const [heartRate, setHeartRate] = useState('');
  const [lastSelectedExercise, setLastSelectedExercise] = useState('');
  const [previousRecords, setPreviousRecords] = useState([]);
  const [proteinRecords, setProteinRecords] = useState([]);
  const [comment, setComment] = useState('');
  const [lastRecordedWeight, setLastRecordedWeight] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const supabase = useSupabase();

  const flashScreen = useCallback(() => {
    if (formRef.current) {
      formRef.current.style.transition = 'background-color 0.3s';
      formRef.current.style.backgroundColor = '#4CAF50';
      setTimeout(() => {
        formRef.current.style.backgroundColor = '';
      }, 300);
    }
  }, []);

  const fetchChartData = useCallback(async () => {
    if (!username || !selectedActivity || category !== 'weightlifting') return;

    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

    const { data: exerciseListData } = await supabase
      .from('exercise_list')
      .select('id')
      .eq('name', selectedActivity)
      .eq('type', category)
      .single();

    if (!exerciseListData) return;

    const { data, error } = await supabase
      .from('exercises')
      .select('*')
      .eq('name_id', exerciseListData.id)
      .eq('username', username)
      .gte('created_at', sixMonthsAgo.toISOString())
      .order('created_at', { ascending: true });

    if (error) {
      console.error('Error fetching chart data:', error);
      return;
    }

    const groupedData = data.reduce((acc, record) => {
      const date = record.created_at.split('T')[0];
      if (!acc[date]) {
        acc[date] = { totalReps: 0, totalWeight: 0, totalWeightLifted: 0, count: 0 };
      }
      if (record.sets && Array.isArray(record.sets)) {
        record.sets.forEach(set => {
          const reps = set.reps || 0;
          const weight = set.weight || 0;
          acc[date].totalReps += reps;
          acc[date].totalWeight += weight;
          acc[date].totalWeightLifted += reps * weight;
          acc[date].count += 1;
        });
      }
      return acc;
    }, {});

    const chartData = Object.entries(groupedData).map(([date, values]) => ({
      date,
      totalWeightLifted: values.totalWeightLifted,
    }));

    setChartData(chartData);
  }, [supabase, username, selectedActivity, category]);

  const renderChart = useCallback(() => {
    if (!chartRef.current || !chartData) return;

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const ctx = chartRef.current.getContext('2d');
    chartInstance.current = new Chart(ctx, {
      type: 'line',
      data: {
        datasets: [
          {
            label: `${selectedActivity} - Total Weight Lifted (kg)`,
            data: chartData.map(d => ({ x: d.date, y: d.totalWeightLifted })),
            borderColor: 'rgb(75, 192, 192)',
            pointRadius: 18,
            pointHoverRadius: 24,
            pointStyle: function(context) {
              if (context.parsed && context.parsed.y !== undefined) {
                const size = Math.max(context.parsed.y / 40, 20);
                const image = new Image(size, size);
                image.src = iconStrength;
                return image;
              }
              return 'circle';
            },
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            labels: {
              usePointStyle: true,
            },
          },
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
              tooltipFormat: 'yyyy-MM-dd (EEEE)',
            },
            title: {
              display: true,
              text: 'Date',
            },
          },
          y: {
            type: 'linear',
            display: true,
            title: {
              display: true,
              text: 'Total Weight Lifted (kg)',
            },
          },
        },
      },
    });
  }, [chartData, selectedActivity]);

  const fetchLastWeight = useCallback(async (exerciseName, userName) => {
    if (!exerciseName || !userName) return;

    const { data: exerciseData } = await supabase
      .from('exercise_list')
      .select('id')
      .eq('name', exerciseName)
      .eq('type', 'weightlifting')
      .single();

    if (exerciseData) {
      const { data: lastExercise } = await supabase
        .from('exercises')
        .select('sets')
        .eq('name_id', exerciseData.id)
        .eq('username', userName)
        .order('created_at', { ascending: false })
        .limit(1);

      if (lastExercise?.[0]?.sets?.length > 0) {
        const lastSet = lastExercise[0].sets[lastExercise[0].sets.length - 1];
        setWeight(lastSet.weight.toString());
        setLastRecordedWeight(lastSet.weight.toString());
      } else {
        setWeight('');
        setLastRecordedWeight('');
      }
    }
  }, [supabase]);

  const fetchPreviousRecords = useCallback(async () => {
    if (!username || !selectedActivity) {
      return;
    }

    const { data: DiaryData, error: DiaryError } = await supabase
      .from('exercise_list')
      .select('id')
      .eq('name', selectedActivity)
      .eq('type', category)
      .single();

    if (DiaryError) {
      console.error('Error fetching exercise_list id:', DiaryError);
      return;
    }

    const today = new Date().toISOString().split('T')[0];

    const { data: todayData, error: todayError } = await supabase
      .from('exercises')
      .select(`
        *,
        exercise_list!inner(id, name)
      `)
      .eq('name_id', DiaryData.id)
      .eq('username', username)
      .gte('created_at', today)
      .lt('created_at', today + ' 23:59:59.999')
      .order('created_at', { ascending: false });

    if (todayError) {
      console.error('Error fetching today\'s records:', todayError);
      return;
    }

    const { data: lastDateData } = await supabase
      .from('exercises')
      .select('created_at')
      .eq('name_id', DiaryData.id)
      .eq('username', username)
      .lt('created_at', `${today}`)
      .order('created_at', { ascending: false })
      .limit(1);

    let previousDayData = [];
    if (lastDateData && lastDateData.length > 0) {
      const lastDate = lastDateData[0].created_at;
      const dateOnly = lastDate.split('T')[0];
      
      const { data: prevData, error: prevError } = await supabase
        .from('exercises')
        .select(`
          *,
          exercise_list!inner(id, name)
        `)
        .eq('name_id', DiaryData.id)
        .eq('username', username)
        .gte('created_at', dateOnly)
        .lt('created_at', dateOnly + ' 23:59:59.999')  // Only get records from the last exercise day
        .order('created_at', { ascending: false });

      if (prevError) {
        console.error('Error fetching previous day records:', prevError);
      } else {
        previousDayData = prevData;
      }
    }

    setPreviousRecords([...todayData, ...previousDayData]);
    
    const latestRecord = todayData.length > 0 ? todayData[0] : (previousDayData.length > 0 ? previousDayData[0] : null);
    if (latestRecord?.sets?.length > 0 && latestRecord.sets[latestRecord.sets.length - 1].weight != null) {
      setLastRecordedWeight(latestRecord.sets[latestRecord.sets.length - 1].weight.toString());
    } else {
      setLastRecordedWeight('');
    }
  }, [supabase, selectedActivity, username, category]);

  const fetchProteinRecords = useCallback(async () => {
    const today = new Date().toISOString().split('T')[0];

    const { data, error } = await supabase
      .from('protein')
      .select('*')
      .eq('username', username)
      .gte('created_at', today)
      .lt('created_at', today + ' 23:59:59.999')
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error fetching protein records:', error);
    } else {
      setProteinRecords(data || []);
    }
  }, [supabase, username]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    if (category === 'protein') {
      await fetchProteinRecords();
      setPreviousRecords([]);
    } else if ((category === 'weightlifting' && selectedActivity) || (category === 'cardio' && selectedActivity)) {
      await fetchPreviousRecords();
      setProteinRecords([]);
    } else {
      setPreviousRecords([]);
      setProteinRecords([]);
    }

    setIsLoading(false);
  }, [fetchPreviousRecords, fetchProteinRecords, category, selectedActivity]);

  // Cache for exercise list data
  const exerciseListCache = useRef({});

  const fetchExerciseList = useCallback(async (category) => {
    if (category === 'protein') {
      return;
    }
    
    // Check cache first
    if (exerciseListCache.current[category]) {
      setExerciseList(exerciseListCache.current[category]);
      return;
    }

    const { data, error } = await supabase
      .from('exercise_list')
      .select('*')
      .eq('type', category)
      .order('name', { ascending: true });

    if (error) {
      console.error('Error fetching exercise list:', error);
    } else {
      exerciseListCache.current[category] = data;
      setExerciseList(data);
    }
  }, [supabase]);

  const shouldFetchRef = useRef(true);

  useEffect(() => {
    const loadData = async () => {
      if (!shouldFetchRef.current) return;
      shouldFetchRef.current = false;
      
      setIsLoading(true);
      try {
        // Only fetch exercise list if needed
        if (category !== 'protein' && !exerciseListCache.current[category]) {
          await fetchExerciseList(category);
        }

        // Fetch main data
        await fetchData();

        // Only fetch chart data for weightlifting with selected activity
        if (category === 'weightlifting' && selectedActivity) {
          await fetchChartData();
        }
      } finally {
        setIsLoading(false);
        // Allow next fetch after a small delay
        setTimeout(() => {
          shouldFetchRef.current = true;
        }, 100);
      }
    };

    loadData();
  }, [category, selectedActivity, username, fetchExerciseList, fetchData, fetchChartData]);

  // Separate useEffect for chart rendering
  useEffect(() => {
    if (category === 'weightlifting' && selectedActivity && chartData) {
      renderChart();
    }
  }, [category, selectedActivity, chartData, renderChart]);

  useEffect(() => {
    if (lastRecordedWeight && weight === '') {
      setWeight(lastRecordedWeight);
    }
  }, [lastRecordedWeight]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if ((category === 'weightlifting' || category === 'cardio') && !selectedActivity) {
      alert('Please select an exercise before recording.');
      return;
    }

    const currentDateTime = new Date().toISOString();
    flashScreen();

    if (category === 'weightlifting') {
      const exerciseData = exerciseListCache.current['weightlifting']?.find(
        exercise => exercise.name === selectedActivity
      );

      if (!exerciseData) {
        console.error('Exercise not found in cache');
        return;
      }

      const newSet = { reps: parseInt(reps), weight: parseFloat(weight) };

      const { error: insertError } = await supabase
        .from('exercises')
        .insert({
          name_id: exerciseData.id,
          username,
          sets: [newSet],
          comment: comment || null,
        });

      if (insertError) {
        console.error('Error inserting new exercise:', insertError);
      } else {
        flashScreen();
        await fetchPreviousRecords();
        setLastRecordedWeight(weight);
      }
    } else if (category === 'cardio') {
      const exerciseData = exerciseListCache.current['cardio']?.find(
        exercise => exercise.name === selectedActivity
      );

      if (!exerciseData) {
        console.error('Exercise not found in cache');
        return;
      }

      const { error } = await supabase
        .from('exercises')
        .insert({
          name_id: exerciseData.id,
          username,
          duration: parseFloat(duration),
          watts: parseInt(watts),
          heart_rate: parseInt(heartRate),
          comment: comment || null,
        });

      if (error) {
        console.error('Error recording cardio exercise:', error);
      } else {
        flashScreen();
        fetchPreviousRecords();
      }
    } else if (category === 'protein') {
      const { error } = await supabase
        .from('protein')  
        .insert({
          username,
          grams: parseInt(protein),
          comment: comment || null,
        });

      if (error) {
        console.error('Error recording protein:', error);
      } else {
        flashScreen();
        fetchProteinRecords();
      }
    }

    setLastSelectedExercise(selectedActivity);
    resetForm();
    setIsLoading(false);
  }, [category, selectedActivity, username, reps, weight, duration, watts, heartRate, protein, comment, supabase, flashScreen, fetchPreviousRecords, fetchProteinRecords]);

  function resetForm() {
    setReps('');
    setDuration('');
    setWatts('');
    setHeartRate('');
    setProtein('');
    setComment('');
  }

  return (
    <div>
      <LoadingOverlay isLoading={isLoading} />
      <h2>Record</h2>
      <form onSubmit={handleSubmit} ref={formRef}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          <button
            type="button"
            onClick={() => {
              setCategory('cardio');
              localStorage.setItem('lastCategory', 'cardio');
              const lastActivity = localStorage.getItem(`lastActivity_${category}`);
              setSelectedActivity(lastActivity || '');
              setPreviousRecords([]); // Clear previous records
              setProteinRecords([]); // Clear protein records
              resetForm();
            }}
            style={{
              background: category === 'cardio' ? '#4CAF50' : 'transparent',
              border: '1px solid #4CAF50',
              padding: '10px 20px',
              cursor: 'pointer',
              outline: 'none',
              flex: 1,
              margin: '0 5px',
            }}
          >
            <FontAwesomeIcon icon={faRunning} size="2x" />
          </button>
          <button
            type="button"
            onClick={() => {
              setCategory('weightlifting');
              localStorage.setItem('lastCategory', 'weightlifting');
              const lastActivity = localStorage.getItem(`lastActivity_${category}`);
              setSelectedActivity(lastActivity || '');
              setPreviousRecords([]); // Clear previous records
              setProteinRecords([]); // Clear protein records
              resetForm();
            }}
            style={{
              background: category === 'weightlifting' ? '#4CAF50' : 'transparent',
              border: '1px solid #4CAF50',
              padding: '10px 20px',
              cursor: 'pointer',
              outline: 'none',
              flex: 1,
              margin: '0 5px',
            }}
          >
            <FontAwesomeIcon icon={faDumbbell} size="2x" />
          </button>
          <button
            type="button"
            onClick={() => {
              setCategory('protein');
              localStorage.setItem('lastCategory', 'protein');
              setSelectedActivity(''); // Clear selected activity
              localStorage.removeItem(`lastActivity_${category}`); // Clear stored activity
              setPreviousRecords([]); // Clear previous records
              setProteinRecords([]); // Clear protein records
              resetForm();
            }}
            style={{
              background: category === 'protein' ? '#4CAF50' : 'transparent',
              border: '1px solid #4CAF50',
              padding: '10px 20px',
              cursor: 'pointer',
              outline: 'none',
              flex: 1,
              margin: '0 5px',
            }}
          >
            <FontAwesomeIcon icon={faDrumstickBite} size="2x" />
          </button>
        </div>

        {(category === 'weightlifting' || category === 'cardio') && (
          <select 
            value={selectedActivity || lastSelectedExercise} 
            onChange={(e) => {
              const newActivity = e.target.value;
              setSelectedActivity(newActivity);
              localStorage.setItem(`lastActivity_${category}`, newActivity);
              localStorage.setItem('lastCategory', category);
              if (category === 'weightlifting') {
                fetchLastWeight(newActivity, username);
              }
            }}
          >
            <option value="">Select Exercise</option>
            {exerciseList.map((exercise) => (
              <option key={exercise.id} value={exercise.name}>{exercise.name}</option>
            ))}
          </select>
        )}

        <select value={username} onChange={(e) => {
          const newUser = e.target.value;
          setUsername(newUser);
          localStorage.setItem('lastUser', newUser);
          if (category === 'weightlifting' && selectedActivity) {
            fetchLastWeight(selectedActivity, newUser);
          }
        }}>
          <option value="Ruben">Ruben</option>
          <option value="Milena">Milena</option>
        </select>

        {category === 'weightlifting' && (
          <div className="weightlifting-inputs">
            <input
              type="number"
              placeholder="Reps"
              value={reps}
              onChange={(e) => setReps(e.target.value)}
              required
            />
            <span className="separator">x</span>
            <input
              type="number"
              placeholder="Weight (kg)"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
              required
            />
          </div>
        )}

        {category === 'cardio' && (
          <>
            <input
              type="number"
              step="0.01"
              placeholder="Duration (minutes)"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              required
            />
            <input
              type="number"
              placeholder="Average Watts"
              value={watts}
              onChange={(e) => setWatts(e.target.value)}
              required
            />
            <input
              type="number"
              placeholder="Heart Rate (bpm)"
              value={heartRate}
              onChange={(e) => setHeartRate(e.target.value)}
              required
            />
          </>
        )}

        {category === 'protein' && (
          <>
            <input
              type="number"
              placeholder="Protein (g)"
              value={protein}
              onChange={(e) => setProtein(e.target.value)}
              required
            />
          </>
        )}

        <input
          type="text"
          placeholder="Comment (optional)"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />

        <button type="submit" className="btn">Record Exercise</button>
      </form>

      {category === 'protein' && proteinRecords.length > 0 && (() => {
        const totalProtein = proteinRecords.reduce((sum, entry) => sum + (entry.grams || 0), 0);
        const proteinLeft = 110 - totalProtein;
        const hasReachedTarget = totalProtein >= 110;
        return (
          <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '-1px' }}>
            <tbody>
              <tr style={{ 
                backgroundColor: hasReachedTarget ? '#d4edda' : '#f8d7da',
                color: hasReachedTarget ? '#155724' : '#721c24',
                fontWeight: 'bold'
              }}>
                <td className="diary-table-row-centered">
                  {hasReachedTarget ? 'Protein target reached!' : `Protein left today: ${proteinLeft}g`}
                </td>
              </tr>
            </tbody>
          </table>
        );
      })()}

      {(((category === 'cardio' || (category === 'weightlifting' && selectedActivity)) && previousRecords.length > 0) || 
        (category === 'protein' && proteinRecords.length > 0)) && (
        <RecordTable 
          category={category}
          records={previousRecords}
          proteinRecords={proteinRecords}
        />
      )}

      {category === 'weightlifting' && selectedActivity && (
        <div style={{ width: '100%', height: '400px', marginTop: '20px' }}>
          <h3>{selectedActivity} - Total Weight Lifted</h3>
          <div style={{ position: 'relative', height: '100%', width: '100%' }}>
            <canvas ref={chartRef}></canvas>
          </div>
        </div>
      )}
    </div>
  );
}

export default Record;
