import React, { createContext, useContext } from 'react';
import { createClient } from '@supabase/supabase-js';

const SupabaseContext = createContext();

export function useSupabase() {
  return useContext(SupabaseContext);
}

export function SupabaseProvider({ children }) {
  const supabase = createClient('https://jkzcyukwocwcnbleztkw.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImpremN5dWt3b2N3Y25ibGV6dGt3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mjc3NzQzNjgsImV4cCI6MjA0MzM1MDM2OH0.hjGD5xaewGfP3viBHh3uOSKAak6zwXkIk8xDtUggJ9s');

  return (
    <SupabaseContext.Provider value={supabase}>
      {children}
    </SupabaseContext.Provider>
  );
}
