import React from 'react';
import { Routes, Route, Link, Navigate } from 'react-router-dom';
import Diary from './components/Diary';
import Record from './components/Record';
import ExerciseTypeManager from './components/ExerciseTypeManager';
import Progress from './components/Progress';
import { SupabaseProvider } from './contexts/SupabaseContext';
import './App.css';

function App() {
  return (
    <SupabaseProvider>
      <div className="container">
        <h1>Gym Exercise Tracker</h1>
        <nav>
          <Link to="/progress" className="btn">Progress</Link>
          <Link to="/diary" className="btn">Diary</Link>
          <Link to="/record" className="btn">Record</Link>
          <Link to="/manage-exercises" className="btn">Manage Exercises</Link>
        </nav>
        <Routes>
          <Route path="/" element={<Navigate to="/progress" replace />} />
          <Route path="/progress" element={<Progress />} />
          <Route path="/diary" element={<Diary />} />
          <Route path="/record" element={<Record />} />
          <Route path="/manage-exercises" element={<ExerciseTypeManager />} />
        </Routes>
      </div>
    </SupabaseProvider>
  );
}

export default App;
