import React, { useState, useEffect, useCallback } from 'react';
import { useSupabase } from '../contexts/SupabaseContext';

function ExerciseTypeManager() {
  const [exerciseTypes, setExerciseTypes] = useState([]);
  const [newExerciseName, setNewExerciseName] = useState('');
  const [newExerciseType, setNewExerciseType] = useState('weightlifting');
  const [editingExercise, setEditingExercise] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const supabase = useSupabase();

  const fetchExerciseTypes = useCallback(async () => {
    const { data, error } = await supabase
      .from('exercise_list')
      .select('id, name, type')
      .order('name', { ascending: true });

    if (error) {
      console.error('Error fetching exercise types:', error);
    } else {
      setExerciseTypes(data);
    }
  }, [supabase]);

  useEffect(() => {
    fetchExerciseTypes();
  }, [fetchExerciseTypes]);

  const addExerciseType = useCallback(async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');
    const { error } = await supabase
      .from('exercise_list')
      .insert({ name: newExerciseName, type: newExerciseType });

    if (error) {
      console.error('Error adding exercise type:', error);
      setErrorMessage('Error adding exercise type. Please try again.');
    } else {
      setNewExerciseName('');
      fetchExerciseTypes();
      setSuccessMessage('Exercise type added successfully!');
    }
    setTimeout(() => {
      setErrorMessage('');
      setSuccessMessage('');
    }, 3000);
  }, [supabase, newExerciseName, fetchExerciseTypes, setErrorMessage, setSuccessMessage]);

  async function updateExerciseType(id, newName) {
    setErrorMessage('');
    setSuccessMessage('');
    try {
      const { error } = await supabase.rpc('update_exercise_type', {
        old_name: '', // This parameter is no longer used, but kept for compatibility
        new_name: newName,
        exercise_id: id
      });

      if (error) throw error;

      setEditingExercise(null);
      await fetchExerciseTypes();
      setSuccessMessage('Exercise type updated successfully!');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      console.error('Error updating exercise type:', error);
      setErrorMessage(`Error updating exercise: ${error.message}. Please try again.`);
    }
  }

  async function deleteExerciseType(id, name) {
    const isConfirmed = window.confirm(`Are you sure you want to delete the exercise "${name}"?`);
    if (isConfirmed) {
      const { error } = await supabase
        .from('exercise_list')
        .delete()
        .eq('id', id);

      if (error) {
        console.error('Error deleting exercise type:', error);
        setErrorMessage('Failed to delete exercise type. Please try again.');
      } else {
        fetchExerciseTypes();
        setSuccessMessage(`Exercise "${name}" has been deleted successfully.`);
      }
    }
  }

  return (
    <div>
      <h2>Manage Exercise Types</h2>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}
      <form onSubmit={addExerciseType}>
        <input
          type="text"
          value={newExerciseName}
          onChange={(e) => setNewExerciseName(e.target.value)}
          placeholder="New exercise name"
          required
        />
        <select
          value={newExerciseType}
          onChange={(e) => setNewExerciseType(e.target.value)}
          required
        >
          <option value="weightlifting">Weightlifting</option>
          <option value="cardio">Cardio</option>
        </select>
        <button type="submit" className="btn">Add Exercise</button>
      </form>
      <div>
        <h3>Cardio Exercises</h3>
        <ul className="exercise-type-list">
          {exerciseTypes.filter(exercise => exercise.type === 'cardio').map((exercise) => (
            <li key={exercise.id} className="exercise-type-item">
              {editingExercise === exercise.id ? (
                <form onSubmit={(e) => {
                  e.preventDefault();
                  updateExerciseType(exercise.id, e.target.exerciseName.value);
                }}>
                  <input
                    name="exerciseName"
                    defaultValue={exercise.name}
                    required
                  />
                  <button type="submit" className="btn">Save</button>
                  <button type="button" className="btn" onClick={() => setEditingExercise(null)}>Cancel</button>
                </form>
              ) : (
                <>
                  {exercise.name} - {exercise.type}
                  <button onClick={() => setEditingExercise(exercise.id)} className="btn">Edit</button>
                  <button onClick={() => deleteExerciseType(exercise.id, exercise.name)} className="btn">Delete</button>
                </>
              )}
            </li>
          ))}
        </ul>
        <h3>Weightlifting Exercises</h3>
        <ul className="exercise-type-list">
          {exerciseTypes.filter(exercise => exercise.type === 'weightlifting').map((exercise) => (
            <li key={exercise.id} className="exercise-type-item">
              {editingExercise === exercise.id ? (
                <form onSubmit={(e) => {
                  e.preventDefault();
                  updateExerciseType(exercise.id, e.target.exerciseName.value);
                }}>
                  <input
                    name="exerciseName"
                    defaultValue={exercise.name}
                    required
                  />
                  <button type="submit" className="btn">Save</button>
                  <button type="button" className="btn" onClick={() => setEditingExercise(null)}>Cancel</button>
                </form>
              ) : (
                <>
                  {exercise.name} - {exercise.type}
                  <button onClick={() => setEditingExercise(exercise.id)} className="btn">Edit</button>
                  <button onClick={() => deleteExerciseType(exercise.id, exercise.name)} className="btn">Delete</button>
                </>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ExerciseTypeManager;
