export const calculateTotalWeight = (sets) => {
  return sets.reduce((total, set) => total + (set.reps * set.weight), 0);
};

export const calculateMissingReps = (todayTotal, previousTotal, lastSet, todaySets = []) => {
  if (previousTotal > todayTotal) {
    // Get the weight from the first record (most recent) and its first set
    const mostRecentWeight = todaySets.length > 0 
      ? todaySets[0].weight 
      : (lastSet?.weight || 0);

    if (mostRecentWeight > 0) {
      const weightDiff = previousTotal - todayTotal;
      const missingReps = Math.ceil(weightDiff / mostRecentWeight);
      return ` (${missingReps} more reps at ${mostRecentWeight}kg to match previous)`;
    }
  }
  return '';
};

export const getTotalRowStyle = (todayTotal, previousTotal) => ({
  fontWeight: 'bold',
  backgroundColor: previousTotal === 0 || todayTotal > previousTotal ? '#e6ffe6' : '#ffe6e6'
});

export const renderWeightliftingHeaders = () => (
  <tr>
    <th>Date</th>
    <th>Reps</th>
    <th>Weight (kg)</th>
    <th>Comment</th>
  </tr>
);

export const renderCardioHeaders = () => (
  <tr>
    <th>Date</th>
    <th>Duration (min)</th>
    <th>Watts</th>
    <th>BPM</th>
    <th>Comment</th>
  </tr>
);

export const renderProteinHeaders = () => (
  <tr>
    <th>Date</th>
    <th>Grams</th>
    <th>Comment</th>
  </tr>
);
